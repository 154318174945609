// vendor styles
//import '../../node_modules/uikit/dist/css/uikit.css'

// vendor js

// set uikit in global variable
//import UIkit from 'uikit'
//window.UIkit = UIkit;

// custom icons
//import Icons from '../../node_modules/uikit/dist/js/uikit-icons';
//UIkit.use(Icons);

import { Fancybox } from "@fancyapps/ui";
window.Fancybox = Fancybox;
import "@fancyapps/ui/dist/fancybox.css";

import Swiper from 'swiper/bundle';
window.Swiper = Swiper;

import 'swiper/css/bundle';

import 'animate.css'

import WOW from 'wow.js'
window.WOW = WOW;